
export default function Button_event ({cta, clase, claseText, event, over, leave, setId}){
    return(
        <>
        <button className={`py-2 px-3 md:px-10 md:py-4 border-2 transition-all ${clase}`} onClick={event} onMouseOver={over} onMouseLeave={leave} id={setId}>
            <p className={`font-medium font-encode ${claseText}`}>{cta}</p>
        </button>
        
        </>
    )
}