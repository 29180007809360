import Card from "@/components/card/card";
import Faq_item from "@/components/faq/faq_item";
import Footer from "@/components/footer/_footer";
import Hero_index from "@/components/hero/hero_index";
import Timeline_steps from "@/components/timeline/timeline";
import Linquei_main from "@/public/brand/linquei_main";
import Image from "next/image";
import CustomHead from "@/components/head/_custom-head";
import Header from "@/components/header/_header";
import Brands from "@/components/brands/brands";
import Isotipo_main from "@/public/brand/isotipo_main";

export default function Home() {
  return (
    <>
    {/* Section head */}
    <CustomHead title="Linquei"/>
    
    {/* Section header */}
    <Header logo={<Linquei_main clase='text-blue-700'/>}/>

    {/* Section hero */}
    <Hero_index 
      subtitle='Inovação para ecossistemas de Conselhos Profissionais e Órgãos Governamentais' 
      background='bg-hero1'>
      A Plataforma Especializada em <span className="bg-gradient-to-t from-red from-40% to-transparent to-40%">Estágios, Residências e Vagas Profissionais</span>
    </Hero_index>

    {/* Section "Com o Linquei Conselhos você tem" */}
    <section className='py-20 px-10 xl:px-0 bg-gradient-to-l from-white from-50% lg:from-80% xl:from-70% to-red to-50% lg:to-80% xl:to-70%'>
      <div className='mx-auto max-w-screen-xl text-blue-700 flex flex-col gap-20'>
        <div className="flex flex-col lg:flex-row items-center gap-10">
          <Image src={'/jpg/linquei-possibilidades.png'} width={400} height={400} className="max-w-[230px] lg:max-w-full" />
          
          <div className="p-8 bg-white border-2 border-grayBorder lg:border-none rounded-xl">
            <h2 className='text-2xl md:text-4xl font-encode font-semibold mb-10'>Uma plataforma, diversas possibilidades</h2>
            <p className="font-dmSans text-xl md:text-2xl/normal"><span className="font-bold">Seja um Órgão Governamental ou um Conselho Profissional,</span> nós temos uma solução de recrutamento e seleção especializada para você.</p>
          </div>
        </div>
        
        <div className="flex flex-col lg:flex-row gap-5 w-full">
          <Card background='bg-card1' title='Gestão dos Programas de Estágio e Residências' description='O Linquei Estágios é especializado na gestão e operacionalização de todo o processo de contratação de vagas de estágio e residências.' href={'/estagios'} button='Linquei Estágios'/>

          <Card background='bg-card2' title='Hub entre profissionais registrados e empresas privadas' description='O Linquei Conselhos é o portal ideal para conectar os melhores profissionais com as empresas que procuram estagiários e profissionais com competências técnicas verificadas.' href={'/conselhos'} button='Linquei Conselhos'/>

          <Card background='bg-card4' title='Integração e fortalecimento do ecossistema profissional' description='A nossa plataforma Linquei Conselhos é ideal para prover a integração de todo o ecossistema em que o Conselho atua e para fomentar a qualificação dos profissionais que o compõe.' href={'/conselhos'} button='Linquei Conselhos'/>

          <Card background='bg-card3' title='Gestão de vagas regulamentadas e não regulamentadas' description='Precisa gerenciar editais e vagas regulamentadas? E se forem vagas não regulamentadas? Não importa, temos a solução para quaisquer tipos de vagas.' href={'mailto:contato@linquei.com'} button='Entre em contato'/>
        </div>
      </div>
    </section>

    {/* Section "Steps" */}
    <section className='bg-blue-600 py-20 px-10 xl:px-0 min-h-[50rem] flex items-center'>
      <div className="mx-auto max-w-screen-xl">
        <p className='text-3xl md:text-4xl font-encode font-semibold mb-10 text-center text-white'>Como funciona?</p>
        <Timeline_steps />
      </div>      
    </section>
    
    {/* Section FAQ */}
    <section className="py-20 px-10 xl:px-0 min-h-[30rem] flex items-center bg-white md:bg-gradient-to-l from-red from-50% md:from-30% to-white to-50% md:to-30%">
      <div className="mx-auto max-w-screen-xl text-blue-600">
          <p className='text-3xl md:text-4xl font-encode font-semibold mb-10 ' data-aos="fade-down">Você já se perguntou se...</p>
          <div className="grid md:grid-cols-2 gap-5">
            <Faq_item>
            Estamos <span className="font-bold text-red">inovando</span> constantemente no modelo de processo de contratação de estagiários, residentes ou profissionais?
            </Faq_item>
            <Faq_item>
            Nós como Órgão Governamental, estamos conseguindo contratar os melhores estagiários e residentes de forma <span className="font-bold text-red">eficiente</span>?
            </Faq_item>
            <Faq_item>
            As empresas que fazem parte do nosso setor de atuação e do Conselho Profissional, estão conseguindo contratar os <span className="font-bold text-red">melhores </span> profissionais?
            </Faq_item>
            <Faq_item>
            Estagiários, Residentes e Profissionais que buscam vagas técnicas em setores específicos estão conseguindo encontrar as vagas de forma <span className="font-bold text-red">simples</span>?
            </Faq_item>
          </div>
        </div>       
    </section>

    {/* Section brands hidden */}
    {/* <Brands /> */}

    {/* Section footer */}
    <Footer callToAction='Para cada uma dessas necessidades, o Linquei possui uma solução' logo={<Linquei_main clase='text-white'/>} isotipo={<Isotipo_main />}/>
    </>)}
