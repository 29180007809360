import Button_event from "../buttons/button_event";
import Steps_item from "./timeline_item";
import { useState } from "react";

/* Items for steps */
const stepsConselhos = [
    { tittle: 'Necessidade', 
      content:{description: 'O Conselho Profissional necessita fomentar o ecossistema em que atua.', textMarked: false ,},
    },

    { tittle: 'Planejamento',
      content: {subtittle: 'Empresas', textMarked: true , description: ' cadastradas no Conselho abrem vagas para profissionais e para estágios.'},
      subcontent: {subtittle: 'Candidatos', textMarked: true , description: ' buscam as vagas especializadas na sua carreira e se candidatam dentro da própria plataforma.'},
    },

    { tittle: 'Operação', 
      content: {subtittle:'Empresas e candidatos', textMarked: true , description:' gerenciam os anúncios e candidaturas respectivamente dentro da própria plataforma.'}
    },

    { tittle: 'Contratação',
      content:{subtittle:'Empresas', textMarked: true ,description: ' contratam os melhores e mais qualificados profissionais de acordo com competências verificadas pelo próprio Conselho.'},
      subcontent:{subtittle:'O Conselho',   textMarked: true ,description: ' acompanha a disponibilidade de vagas, contratações e o desenvolvimento do ecossistema.'},
    },
  ];

const stepsGovernos = [
    {tittle: 'Necessidade', content:{description: 'Órgão necessita abrir e gerenciar vagas regulamentadas de estágios e residências.'}, },
    {tittle: 'Planejamento', content:{description: 'As vagas do edital são abertas dentro da plataforma do Linquei Estágios, de acordo com os critérios, requisitos e legislações vigentes como a Lei Federal 11788/2008 e a Resolução CNMP 42/2009 e demais atos e leis estaduais.'}, },
    {tittle: 'Operação', content:{description: 'O Órgão operacionaliza e gerencia todo o processo de contratação em um só lugar.'}, },
    {tittle: 'Contratação', content:{description: 'O Órgão finaliza as contratações de acordo com as classificações e critérios configurados na plataforma.'}, },
];

export default function Timeline_steps (){
    const [change, setChange] = useState (true);

    const changeSteps = () => {
        setChange(false)
    }

    const changeStepsBack = () => {
        setChange(true)
    }

    return(
        <>
        <div className="flex flex-col gap-20">
            <div className="flex mx-auto flex-row justify-center items-center border border-white rounded-full">
                <Button_event cta='Para Conselhos' event={changeStepsBack} clase={change ? 'bg-white text-blue-700 border-white rounded-l-full' : 'bg-transparent border-transparent text-white/50 hover:text-white'} />
                <Button_event cta='Para Governos' event={changeSteps} clase={change ? 'bg-transparent border-transparent text-white/50 hover:text-white' : 'bg-white text-blue-700 border-white rounded-r-full'} />
            </div>

            <div className={change ? 'hidden' : 'flex flex-col lg:flex-row'}>
                {stepsGovernos.map((item) => (
                <Steps_item
                    key={item.tittle}
                    tittle={item.tittle}
                    description={item.content?.description}
                />
                ))}                
            </div>

            <div className={change ? 'flex flex-col lg:flex-row' : 'hidden'}>
                {stepsConselhos.map((item) => (
                <Steps_item
                    key={item.tittle}
                    tittle={item.tittle}
                    textMarked={item.content?.textMarked || false}
                    subtittle={item.content?.subtittle}
                    description={item.content?.description}
                    subSubtittle={item.subcontent?.subtittle}
                    subDescription={item.subcontent?.description}
                />
                ))}
            </div>
        </div>        
        </>
    )
}