import Facebook_icon from "@/public/clients/Facebook";
import Google_icon from "@/public/clients/Google";
import Pinterest_icon from "@/public/clients/Pinterest";
import Twich_icon from "@/public/clients/Twitch";
import Webflow_icon from "@/public/clients/Webflow";
import Youtube_icon from "@/public/clients/YouTube";

export default function Brands (){
    return(
        <>
        <section className="mx-auto max-w-screen-lg py-20 px-10 lg:px-0 text-blue-600 text-center">
            <p className='text-3xl md:text-4xl font-encode font-semibold mb-16' data-aos="fade-down">Alguns clientes do Grupo Dezzenvolve</p>
            <div className="w-full grid md:grid-cols-3 lg:grid-cols-6 gap-10 md:gap-5 items-center justify-center">
            <div className="flex items-center justify-center"><Google_icon/></div>
            <div className="flex items-center justify-center"><Facebook_icon/></div>
            <div className="flex items-center justify-center"><Youtube_icon/></div>
            <div className="flex items-center justify-center"><Pinterest_icon /></div>
            <div className="flex items-center justify-center"><Twich_icon/></div>
            <div className="flex items-center justify-center"><Webflow_icon/></div>    
            </div>
        </section>        
        </>
    )
}