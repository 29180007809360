export default function Point_step() {
  return(
    <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={57}
    fill="none"
  >
    <circle cx={15} cy={15} r={15} fill="#E93641" />
    <path
      stroke="#E93641"
      strokeLinecap="round"
      strokeWidth={2}
      d="M15 16v40"
    />
  </svg>
  </>
)}