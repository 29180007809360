export default function Faq_icon () { 
    return (
        <>  
        <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none">
        <path
            fill="#E93641"
            fillRule="evenodd"
            d="M13.986 17.056c-.888 0-1.607-.74-1.47-1.615.86-5.316 4.91-9.191 11.938-9.191 7.032 0 11.772 4.25 11.772 10.047 0 4.2-2.078 7.15-5.593 9.29-3.438 2.06-4.42 3.494-4.42 6.282v.093a1.563 1.563 0 0 1-1.562 1.563h-2.406a1.563 1.563 0 0 1-1.562-1.547l-.01-.625c-.134-3.816 1.49-6.253 5.14-8.475 3.22-1.975 4.366-3.547 4.366-6.337 0-3.06-2.368-5.307-6.018-5.307-3.153 0-5.344 1.653-6.057 4.382-.206.793-.868 1.44-1.687 1.44h-2.431Zm9.44 26.694c1.944 0 3.422-1.481 3.422-3.406 0-1.931-1.478-3.413-3.422-3.413-1.893 0-3.397 1.482-3.397 3.41 0 1.928 1.504 3.409 3.397 3.409Z"
            clipRule="evenodd"
        />
        </svg>
    </>
  )
}
  