export default function Linquei_main({clase}){
    return (
        <>
        <svg width={166} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" className={`w-52 ${clase}`}>
        <g clip-path="url(#clip0_780_185)">
        <path d="M0 25.7167V3.31055H3.87558V25.6738C3.87558 27.4114 4.5227 28.0156 6.17805 28.0156V31.5694C2.09868 31.5694 0 29.9963 0 25.7167Z" fill="currentColor"/>
        <path d="M10.3354 3.31055C11.7905 3.31055 13.0418 4.52256 13.0418 5.97411C13.0418 7.46857 11.7905 8.68059 10.3354 8.68059C8.84092 8.68059 7.62891 7.46857 7.62891 5.97411C7.62891 4.52256 8.84092 3.31055 10.3354 3.31055ZM8.47624 25.7167V11.101H12.3518V25.6738C12.3518 27.4114 12.9989 28.0156 14.6543 28.0156V31.5694C10.5749 31.5694 8.47624 29.9963 8.47624 25.7167ZM14.6328 31.5694V28.0156C16.2882 28.0156 16.9353 27.4114 16.9353 25.6738V11.101H20.8109V25.7167C20.8109 29.9963 18.7122 31.5694 14.6328 31.5694Z" fill="currentColor"/>
        <path d="M16.9355 11.101H20.7718V14.7442C22.588 12.0806 25.534 10.8293 28.7232 10.7506C34.819 10.5075 40.4715 14.544 40.4715 21.8482L41.0042 29.8175L40.4715 29.2705C38.7983 28.062 37.0392 25.0302 36.7067 22.8529L36.5959 21.8518C36.5959 16.8893 33.203 14.6298 28.7232 14.6298C24.2005 14.6298 20.7718 16.8893 20.7718 21.8518V26.4353C20.661 29.8032 18.1333 31.2297 16.9355 31.3584V11.101Z" fill="currentColor"/>
        <path d="M58.7129 20.8723V11.1011H62.5885V20.8723C62.5885 25.8383 65.9814 28.0979 70.4612 28.0979C74.9839 28.0979 78.4126 25.8383 78.4126 20.8723V11.1011H82.2488V31.5694H78.4126V27.9763C76.5963 30.6399 73.6503 31.8912 70.4612 31.9734C64.3618 32.2166 58.7129 28.1801 58.7129 20.8723Z" fill="currentColor"/>
        <path d="M83.875 21.3979C83.875 14.4547 89.5275 10.7007 96.7138 10.7007C103.9 10.7007 109.592 14.4547 109.592 22.0843H87.7935C88.1153 26.1601 91.5082 28.1801 96.7138 28.1801C100.872 28.1801 103.818 26.9288 105.069 24.4261L108.541 26.0421C106.564 29.957 102.08 32.0164 96.7138 32.0164C89.5275 32.0164 83.875 28.341 83.875 21.3979ZM104.744 18.9345C103.896 16.0278 100.829 14.5334 96.7102 14.5334C92.5915 14.5334 89.5632 16.0278 88.6766 18.9345H104.744Z" fill="currentColor"/>
        <path d="M114.111 3.31055C115.566 3.31055 116.817 4.52256 116.817 5.97411C116.817 7.46857 115.566 8.68059 114.111 8.68059C112.616 8.68059 111.404 7.46857 111.404 5.97411C111.404 4.52256 112.616 3.31055 114.111 3.31055ZM112.252 25.7167V11.101H116.127V25.6738C116.127 27.4114 116.774 28.0156 118.43 28.0156V31.5694C114.354 31.5694 112.252 29.9963 112.252 25.7167Z" fill="currentColor"/>
        <path d="M58.6053 11.101V14.6119C56.6675 11.9877 53.3175 10.7757 49.4419 10.697C45.0479 10.5504 41.2296 11.9412 38.9235 14.6334C39.8567 15.899 40.4931 17.3613 40.8148 18.9809C41.8088 16.0099 44.9049 14.569 49.4384 14.569C55.3733 14.569 58.4015 16.9108 58.4409 21.312C58.4802 25.2269 55.5735 28.0942 49.4384 28.0942C44.0647 28.0942 40.7112 25.9813 40.4823 21.7339L40.468 21.7088C40.468 19.2634 39.7387 17.0109 38.4552 15.2126C37.2753 16.8358 36.5996 18.8701 36.5996 21.2691C36.5996 28.2122 42.2521 32.2094 49.4384 31.9662C53.3139 31.884 56.5853 30.6756 58.6018 27.9691V39.3527H62.5167V11.101H58.6053Z" fill="currentColor"/>
        <path d="M158.434 24.7086C157.075 26.532 154.869 27.3472 152.259 27.4008C150.064 27.4759 148.083 26.961 146.575 25.9421V25.9457L146.567 25.9385C145.019 24.8945 143.968 23.3178 143.689 21.3014C143.686 21.2871 143.682 21.2692 143.679 21.2549L143.664 21.1405C143.657 21.0869 143.654 21.0368 143.646 20.9832L143.603 20.5828C143.603 17.2399 141.319 15.7168 138.298 15.7168C135.252 15.7168 132.938 17.2399 132.938 20.5828V23.6718C132.863 25.9421 131.158 26.9038 130.354 26.9896V13.3357H132.938V15.7919C134.161 13.9971 136.149 13.1534 138.298 13.0997C140.908 12.9961 143.396 14.0579 144.862 16.1065C146.367 14.04 149.092 12.9603 152.259 13.0676C154.873 13.1212 157.129 13.9399 158.434 15.7061V13.3357H161.072L160.883 33.0354C163.893 29.5352 165.713 24.9803 165.713 20C165.713 8.95602 156.757 0 145.713 0C134.669 0 125.713 8.95602 125.713 20C125.713 31.0476 134.669 40 145.713 40C150.461 40 154.823 38.3447 158.251 35.581L158.434 24.7086Z" fill="currentColor"/>
        <path d="M152.255 15.6775C148.501 15.6775 146.209 17.1398 146.209 20.1859C146.209 23.2606 148.501 24.7908 152.255 24.7908C156.391 24.7908 158.351 22.8602 158.322 20.2216C158.297 17.2542 156.255 15.6775 152.255 15.6775Z" fill="currentColor"/>
        <path d="M103.003 39.2384C102.564 39.2384 102.145 39.2491 101.731 39.2313C101.638 39.2277 101.523 39.1419 101.459 39.0596C101.001 38.459 100.558 37.8476 100.107 37.2398C100.032 37.1397 99.9537 37.0432 99.8179 36.868C99.8179 37.3328 99.8179 37.7082 99.8179 38.0836C99.8179 38.4554 99.8179 38.8237 99.8179 39.217C99.2816 39.217 98.7632 39.217 98.2305 39.217C98.2305 37.4865 98.2305 35.7704 98.2305 34.0078C98.7131 34.0078 99.1886 33.9971 99.6642 34.0185C99.7428 34.0221 99.8286 34.1365 99.8894 34.2187C100.379 34.873 100.865 35.5309 101.409 36.2674C101.409 35.4701 101.409 34.7586 101.409 34.0293C101.959 34.0293 102.467 34.0293 103 34.0293C103.003 35.749 103.003 37.4758 103.003 39.2384Z" fill="currentColor"/>
        <path d="M83.8258 34.0256C83.8258 34.5011 83.8258 34.9552 83.8258 35.4486C83.0035 35.4486 82.1883 35.4486 81.3481 35.4486C81.3481 35.6345 81.3481 35.7882 81.3481 35.9706C82.1097 35.9706 82.8641 35.9706 83.6435 35.9706C83.6435 36.4068 83.6435 36.8143 83.6435 37.2505C82.8748 37.2505 82.1204 37.2505 81.3446 37.2505C81.3446 37.4436 81.3446 37.6045 81.3446 37.8047C82.1812 37.8047 83.0106 37.8047 83.858 37.8047C83.858 38.2909 83.858 38.7414 83.858 39.2205C82.4958 39.2205 81.1372 39.2205 79.75 39.2205C79.75 37.4972 79.75 35.7739 79.75 34.0292C81.105 34.0256 82.4457 34.0256 83.8258 34.0256Z" fill="currentColor"/>
        <path d="M95.2668 37.2435C95.2668 37.4402 95.2668 37.5939 95.2668 37.7977C96.107 37.7977 96.94 37.7977 97.7909 37.7977C97.7909 38.2982 97.7909 38.7487 97.7909 39.2207C96.4144 39.2207 95.0523 39.2207 93.6758 39.2207C93.6758 37.4902 93.6758 35.7741 93.6758 34.033C95.0272 34.033 96.3787 34.033 97.748 34.033C97.748 34.4977 97.748 34.959 97.748 35.4595C96.9221 35.4595 96.107 35.4595 95.2703 35.4595C95.2703 35.6382 95.2703 35.7848 95.2703 35.9672C96.0319 35.9672 96.7934 35.9672 97.5764 35.9672C97.5764 36.4069 97.5764 36.8038 97.5764 37.2471C96.8041 37.2435 96.0426 37.2435 95.2668 37.2435Z" fill="currentColor"/>
        <path d="M126.955 34.0256C126.955 34.5011 126.955 34.9552 126.955 35.4486C126.132 35.4486 125.317 35.4486 124.477 35.4486C124.477 35.6345 124.477 35.7882 124.477 35.9706C125.239 35.9706 125.993 35.9706 126.772 35.9706C126.772 36.4068 126.772 36.8143 126.772 37.2505C126.004 37.2505 125.249 37.2505 124.473 37.2505C124.473 37.4436 124.473 37.6045 124.473 37.8047C125.31 37.8047 126.14 37.8047 126.987 37.8047C126.987 38.2909 126.987 38.7414 126.987 39.2205C125.625 39.2205 124.266 39.2205 122.879 39.2205C122.879 37.4972 122.879 35.7739 122.879 34.0292C124.234 34.0256 125.575 34.0256 126.955 34.0256Z" fill="currentColor"/>
        <path d="M86.476 37.8083C87.2447 37.8083 87.9276 37.8083 88.6248 37.8083C88.6248 38.2874 88.6248 38.7415 88.6248 39.217C87.1446 39.217 85.6644 39.217 84.1414 39.217C84.1414 38.8344 84.1271 38.4447 84.1485 38.055C84.1557 37.9549 84.2594 37.8584 84.3345 37.769C84.9637 37.0218 85.5965 36.2746 86.2687 35.4773C85.5643 35.4773 84.9101 35.4773 84.2343 35.4773C84.2343 34.9803 84.2343 34.5155 84.2343 34.0293C85.6788 34.0293 87.1375 34.0293 88.6355 34.0293C88.6355 34.4118 88.6426 34.8016 88.6283 35.1877C88.6248 35.2663 88.539 35.3486 88.4782 35.4201C87.8954 36.1137 87.3091 36.8037 86.7263 37.4937C86.6584 37.576 86.5976 37.6582 86.476 37.8083Z" fill="currentColor"/>
        <path d="M91.1596 37.8083C91.9319 37.8083 92.6112 37.8083 93.3084 37.8083C93.3084 38.2874 93.3084 38.7415 93.3084 39.217C91.8246 39.217 90.348 39.217 88.825 39.217C88.825 38.8309 88.8107 38.4412 88.8321 38.055C88.8393 37.9549 88.9465 37.8584 89.018 37.769C89.6473 37.0218 90.2801 36.2746 90.9523 35.4773C90.2444 35.4773 89.5901 35.4773 88.9144 35.4773C88.9144 34.9803 88.9144 34.5155 88.9144 34.0293C90.3623 34.0293 91.8175 34.0293 93.3191 34.0293C93.3191 34.4011 93.3298 34.7801 93.3119 35.1591C93.3084 35.2485 93.2225 35.345 93.1582 35.4236C92.5754 36.1172 91.9891 36.8073 91.4063 37.4973C91.3455 37.576 91.2812 37.6582 91.1596 37.8083Z" fill="currentColor"/>
        <path d="M103.262 34.0078C103.834 34.0078 104.356 34.015 104.878 34.0042C105.067 34.0007 105.103 34.1043 105.146 34.2438C105.393 35.0339 105.646 35.8205 105.9 36.607C105.922 36.675 105.95 36.7429 106 36.8787C106.233 36.153 106.451 35.4844 106.665 34.8158C106.923 34.0078 106.919 34.0078 107.759 34.0078C108.056 34.0078 108.356 34.0078 108.685 34.0078C108.657 34.1079 108.642 34.1866 108.614 34.2617C108.028 35.8562 107.438 37.4472 106.855 39.0418C106.787 39.2277 106.698 39.3206 106.487 39.2992C106.308 39.2813 106.126 39.2956 105.943 39.2956C105.2 39.2956 105.2 39.2956 104.949 38.6199C104.427 37.2077 103.905 35.7919 103.383 34.3796C103.348 34.2652 103.312 34.158 103.262 34.0078Z" fill="currentColor"/>
        <path d="M119.2 39.2922C118.549 37.5367 117.902 35.7884 117.248 34.0187C117.874 34.0187 118.456 34.0187 119.064 34.0187C119.365 34.9518 119.665 35.8885 119.987 36.9039C120.273 36.0101 120.548 35.1985 120.784 34.3762C120.87 34.0687 121.013 33.9829 121.317 34.0008C121.764 34.0258 122.211 34.0079 122.704 34.0079C122.532 34.4691 122.375 34.8946 122.218 35.32C121.764 36.5535 121.302 37.787 120.855 39.024C120.784 39.2207 120.698 39.3136 120.469 39.3029C120.055 39.2779 119.636 39.2922 119.2 39.2922Z" fill="currentColor"/>
        <path d="M118.006 39.2241C116.709 39.2241 115.439 39.2241 114.152 39.2241C114.152 37.4937 114.152 35.774 114.152 34.0293C114.667 34.0293 115.186 34.0293 115.75 34.0293C115.75 35.2413 115.75 36.4676 115.75 37.7333C116.526 37.7333 117.259 37.7333 118.01 37.7333C118.006 38.2481 118.006 38.7236 118.006 39.2241Z" fill="currentColor"/>
        <path d="M77.4195 34.1259C76.5078 33.965 75.5604 34.0079 74.6094 33.9614C74.6094 35.7598 74.6094 37.4759 74.6094 39.2278C75.3745 39.2278 76.1146 39.2743 76.8475 39.2171C78.3276 39.1027 79.1607 38.2589 79.3108 36.8109C79.4503 35.4666 78.7388 34.3619 77.4195 34.1259ZM77.5268 37.2328C77.2229 37.694 76.751 37.7226 76.2397 37.6976C76.2397 36.9754 76.2397 36.2818 76.2397 35.5882C76.2826 35.5774 76.3255 35.5667 76.372 35.5596C76.7688 35.4952 77.1907 35.6203 77.4303 35.8778C77.4481 35.8956 77.466 35.9171 77.4803 35.9385C77.7199 36.2603 77.7592 36.8824 77.5268 37.2328Z" fill="currentColor"/>
        <path d="M113.702 36.5034C113.598 34.8838 112.411 33.7898 110.874 33.8899C109.429 33.9828 108.353 35.2771 108.443 36.8073C108.532 38.3411 109.744 39.4494 111.231 39.3565C112.676 39.2671 113.798 37.9728 113.702 36.5034ZM111.832 37.3901C111.432 37.9407 110.663 37.9335 110.298 37.3543C110.162 37.1398 110.112 36.8681 110.023 36.625C110.08 35.9099 110.523 35.413 111.131 35.4773C111.346 35.4988 111.589 35.6167 111.743 35.7705C112.15 36.1673 112.172 36.9253 111.832 37.3901Z" fill="currentColor"/>
        <path d="M66.3184 36.6643H67.3945C68.1453 36.6643 68.4349 36.9932 68.4349 37.4544C68.4349 37.6046 68.3813 37.7512 68.2418 37.8584C68.5851 37.9907 68.7281 38.2624 68.7281 38.5592C68.7281 39.024 68.4027 39.36 67.6555 39.36H66.3184V36.6643ZM67.4446 37.7476C67.8807 37.7512 67.963 37.6404 67.963 37.4544C67.963 37.222 67.8772 37.1327 67.3945 37.1327H66.7867V37.7405L67.4446 37.7476ZM67.727 38.8953C68.1489 38.8988 68.2597 38.788 68.2597 38.5592C68.2597 38.3089 68.1632 38.216 67.7234 38.216H66.7867V38.8881L67.727 38.8953Z" fill="currentColor"/>
        <path d="M69.6188 38.366L68.3496 36.6606H68.9538L69.8476 37.8512L70.7415 36.6606H71.3457L70.08 38.366V39.36H69.6153V38.366H69.6188Z" fill="currentColor"/>
        <path d="M71.6562 37.8191C71.6562 37.6582 71.7957 37.5188 71.9637 37.5188C72.1246 37.5188 72.264 37.6582 72.264 37.8191C72.264 37.9872 72.1246 38.123 71.9637 38.123C71.7957 38.123 71.6562 37.9907 71.6562 37.8191ZM71.9673 38.7558C72.1282 38.7558 72.2676 38.8881 72.2676 39.0562C72.2676 39.2206 72.1282 39.3601 71.9673 39.3601C71.7993 39.3601 71.6598 39.2206 71.6598 39.0562C71.6562 38.8881 71.7957 38.7558 71.9673 38.7558Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_780_185">
        <rect width="165.713" height="40" fill="currentColor"/>
        </clipPath>
        </defs>
        </svg>        
        </>
    )
}


