export default function Google_icon(){
    return(
        <>
        <svg width={113} height={37} viewBox="0 0 113 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4396 0H15.4329C18.8811 0.075763 22.2732 1.47738 24.7094 3.95862C23.8099 4.88671 22.8916 5.77693 22.0108 6.70503C20.6427 5.45494 18.9936 4.48896 17.1945 4.14802C14.5333 3.5798 11.6661 4.0912 9.43594 5.6254C6.99968 7.23537 5.35052 9.96284 4.99445 12.8797C4.6009 15.7587 5.40674 18.7892 7.26204 21.0242C9.04239 23.2024 11.7598 24.5662 14.5708 24.6609C17.1945 24.8124 19.9306 23.9979 21.8421 22.1417C23.3414 20.8348 24.0348 18.846 24.2596 16.9141C21.1487 16.9141 18.0378 16.933 14.9269 16.9141V13.0123H28.0265C28.7011 17.1982 27.7266 21.9144 24.5782 24.9071C22.4793 27.0285 19.5745 28.2785 16.6135 28.5248C13.7462 28.8089 10.7853 28.2596 8.23655 26.858C5.18185 25.2101 2.74559 22.4258 1.5462 19.1491C0.421773 16.1375 0.403032 12.7282 1.4525 9.69767C2.40826 6.93231 4.24483 4.48896 6.60613 2.78429C8.87372 1.09856 11.6286 0.170467 14.4396 0Z" fill="#1E285F"/>
            <path d="M89.5898 0.984985H93.6003V28.0323C92.2697 28.0323 90.9204 28.0513 89.5898 28.0134C89.6086 19.0165 89.5898 10.0008 89.5898 0.984985Z" fill="#1E285F"/>
            <path d="M36.947 10.3607C39.4208 9.88719 42.1007 10.4175 44.1434 11.9138C45.9987 13.2397 47.2918 15.3231 47.6853 17.596C48.1913 20.2288 47.5542 23.1077 45.8675 25.1912C44.0497 27.5209 41.07 28.771 38.1652 28.5816C35.504 28.43 32.9178 27.0853 31.3436 24.8881C29.5633 22.4638 29.1323 19.1302 30.1068 16.2891C31.0813 13.2397 33.8361 10.91 36.947 10.3607ZM37.5093 13.9594C36.4973 14.2246 35.5602 14.8117 34.8856 15.6451C33.0678 17.8422 33.1802 21.422 35.1854 23.4676C36.3286 24.6419 38.0527 25.1912 39.6457 24.8692C41.1262 24.604 42.4193 23.6002 43.1314 22.2743C44.3683 20.0204 44.0122 16.9142 42.1194 15.1527C40.9013 14.0162 39.1209 13.5427 37.5093 13.9594Z" fill="#1E285F"/>
            <path d="M56.8103 10.3606C59.6401 9.81129 62.7323 10.6068 64.85 12.6145C68.2982 15.7398 68.673 21.5925 65.7308 25.1912C63.9504 27.4641 61.0644 28.7142 58.2158 28.5816C55.4985 28.5058 52.8186 27.1421 51.2069 24.8882C49.3891 22.4069 48.9955 18.9976 50.0262 16.0996C51.057 13.1449 53.7556 10.8909 56.8103 10.3606ZM57.3725 13.9593C56.3605 14.2245 55.4235 14.8117 54.7488 15.6261C52.9498 17.7854 53.0247 21.3083 54.955 23.3729C56.0982 24.604 57.8785 25.2102 59.5277 24.8692C60.9894 24.5851 62.3013 23.6002 63.0134 22.2743C64.2315 20.0014 63.8755 16.8951 61.9639 15.1337C60.7458 13.9972 58.9655 13.5426 57.3725 13.9593Z" fill="#1E285F"/>
            <path d="M73.6601 11.4592C75.8153 10.0955 78.6826 9.71669 81.0064 10.891C81.7373 11.213 82.337 11.7623 82.9179 12.3116C82.9367 11.8002 82.9179 11.2698 82.9367 10.7395C84.1923 10.7584 85.4479 10.7395 86.7223 10.7584V27.4641C86.7035 29.9833 86.0663 32.6539 84.2672 34.4911C82.2995 36.5178 79.2823 37.1428 76.5649 36.7261C73.6601 36.2905 71.1302 34.1502 70.0057 31.4417C71.1302 30.8924 72.3108 30.4568 73.4727 29.9454C74.1286 31.4985 75.4592 32.8244 77.1271 33.1274C78.795 33.4305 80.7253 33.0138 81.8122 31.6122C82.9741 30.1727 82.9741 28.2028 82.9179 26.4413C82.0559 27.2937 81.0626 28.0513 79.8632 28.3354C77.2583 29.0741 74.391 28.1649 72.3858 26.3845C70.3618 24.6041 69.1624 21.8577 69.2748 19.1302C69.3311 16.0429 71.0552 13.0692 73.6601 11.4592ZM77.5394 13.8836C76.3962 14.0731 75.328 14.717 74.5971 15.6073C72.8355 17.7286 72.8355 21.119 74.6159 23.2025C75.6279 24.4336 77.2583 25.1155 78.8325 24.945C80.313 24.7935 81.6811 23.8465 82.4119 22.5396C83.6488 20.3235 83.4427 17.3119 81.7748 15.38C80.744 14.1867 79.0949 13.5995 77.5394 13.8836Z" fill="#1E285F"/>
            <path d="M98.4137 12.4252C100.663 10.3038 104.13 9.58409 107.016 10.7016C109.752 11.7433 111.495 14.3761 112.375 17.0846C108.309 18.7893 104.261 20.475 100.194 22.1797C100.756 23.2593 101.618 24.2442 102.78 24.642C104.411 25.2291 106.36 25.0208 107.728 23.9222C108.271 23.5055 108.702 22.9562 109.115 22.4259C110.145 23.1267 111.176 23.8086 112.207 24.5094C110.745 26.7255 108.29 28.2786 105.648 28.5248C102.724 28.8847 99.6131 27.7483 97.7203 25.4375C94.6094 21.8009 94.9092 15.7019 98.4137 12.4252ZM100.419 15.9292C99.7818 16.8573 99.5194 17.9938 99.5381 19.1113C102.256 17.9748 104.973 16.8384 107.69 15.683C107.24 14.6223 106.154 13.9783 105.048 13.8079C103.267 13.4859 101.412 14.4519 100.419 15.9292Z" fill="#1E285F"/>
        </svg>
        </>
    )
}