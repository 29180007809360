import Less_button from "@/public/icons/less_button";
import Pluss_button from "@/public/icons/plus_button";
import ButtonCard from "../buttons/button_card";
import { useState } from "react";

export default function Card ({background, title, description, href, button}){
    const[show, setShow] = useState('');

    const cardToggle = () => {
        setShow(!show)
    }

    return(
        <>
        <div className={show ? 'hidden' : `${background} bg-cover bg-center w-full h-[23rem] rounded-lg relative overflow-hidden transition-all`} onMouseOver={cardToggle}>
            <div className="text-white bg-black/60 absolute top-0 left-0 w-full h-full p-5">
                <div>
                    <p className="text-xl font-encode font-medium">{title}</p>
                    <div className="absolute bottom-3 right-3 text-red" onClick={cardToggle}>
                        <Pluss_button/>
                    </div> 
                </div>               
            </div>
        </div>
         
        <div className={show ? `${background} bg-cover bg-center w-full h-[23rem] rounded-lg relative overflow-hidden transition-all` : 'hidden' } onMouseLeave={cardToggle}>
            <div className="text-blue-700 bg-white/90 absolute top-0 left-0 w-full h-full p-5">
                <div>
                    <p className="font-encode text-base/tight lg:text-sm/tight font-bold mb-5">{title}</p>
                    <p className="font-encode text-base/tight lg:text-sm/tight font-medium mb-5">{description}</p>
                    <div className="absolute bottom-0 right-0 flex justify-between items-center gap-5 w-full p-3">
                        <ButtonCard href={href} cta={button} claseText='text-red' clase='border-red max-w-xs' iconColor='text-red'/>
                        <div className="text-red" onClick={cardToggle}><Less_button/></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}