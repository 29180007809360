import { useState } from "react";
import Button_event from "../buttons/button_event";
import Link from "next/link";

export default function Hero_index({children, subtitle, background}) {

  const [heroColor_r, setHeroColor_r] = useState ();
  const [heroColor_l, setHeroColor_l] = useState ();
  const [responsive, setResponsive] = useState ();

  function heroBackground_r() {
    setHeroColor_r(!heroColor_r)
  }

  function heroBackground_l() {
    setHeroColor_l(!heroColor_l)
  }

    return (
    <>
      <section className={`h-[40rem] ${background} bg-cover bg-center px-10 lg:px-0 relative overflow-hidden`}>
        <div className={`${heroColor_l ? 'hero_content_open hero-color_l bg-orange left-0' : ''} hero_content_close_l transition-all duration-700 absolute bottom-0 left-0 z-0`}></div>
        <div className={`${heroColor_r ? 'hero_content_open hero-color_r bg-yellow right-0' : ''} hero_content_close_r transition-all duration-700 absolute bottom-0 right-0 z-0`}></div>
        <div className={`h-full w-full absolute top-0 left-0 z-10`} >

          {/* Desktop design */}
          <div className={`hidden lg:max-w-screen-xl lg:mx-auto lg:flex lg:flex-row lg:justify-between lg:items-center lg:h-full lg:gap-5 `}>

            <Link href={'conselhos'}>
              <div onMouseOver={heroBackground_l} onMouseLeave={heroBackground_l} className="font-medium font-encode text-white hover:text-orange hover:bg-white hover:border-transparent rounded-full py-2 px-5 border-2 border-white transition-all ">
                Para Conselhos
              </div>
            </Link>


            <div className='text-center w-4/6'>
              <h1 className='text-white text-3xl md:text-5xl/tight font-semibold font-encode mb-10' data-aos="fade-up" >
                {children}
              </h1>
      
              <p className="text-2xl text-white font-dmSans">{subtitle}</p>
            </div>

            <Link href={'estagios'}>
              <div onMouseOver={heroBackground_r} onMouseLeave={heroBackground_r} className="font-medium font-encode text-white hover:text-yellow hover:bg-white hover:border-transparent rounded-full py-2 px-5 border-2 border-white transition-all ">
                Para Governos
              </div>
            </Link>

          </div>

          {/* Responsive design */}
          <div className={`px-10 flex flex-col  justify-center items-center h-full gap-5 lg:hidden`}>

            <div className='text-center'>
              <h1 className='text-white w-full text-3xl font-semibold font-encode mb-5' data-aos="fade-up" >
                {children}
              </h1>
      
              <p className="text-2xl text-white font-dmSans">{subtitle}</p>
            </div>

            <div className="flex flex-row gap-5">
              <Link href={'conselhos'}>
                <div className="font-medium font-encode text-white hover:text-blue-700 hover:bg-white hover:border-transparent rounded-full py-2 px-5 border-2 border-white transition-all ">
                  Para Conselhos
                </div>
              </Link>

              <Link href={'estagios'}>
                <div className="font-medium font-encode text-white hover:text-blue-700 hover:bg-white hover:border-transparent rounded-full py-2 px-5 border-2 border-white transition-all ">
                  Para Governos
                </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
    </>
    )
}

//{bg ? 'bg-gradient-to-l from-orange from-40% to-transparent to-40%' : 'bg-gradient-to-l from-yellow from-40% to-transparent to-40%'}