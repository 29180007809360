import Faq_icon from "@/public/icons/faq";

export default function Faq_item({children}){
    return(
        <>
        <div className="bg-white border-2 border-grayBorder rounded-xl flex flex-col md:flex-row justify-center items-center gap-2 p-5 w-full">
            <div className="flex justify-center items-center"><Faq_icon /></div>
            <p className="font-dmSans">{children}</p>
        </div>
        </>
    )
}