import Point_step from "@/public/icons/point_step";
import { useState } from "react";

export default function Steps_item({tittle, subtittle, description, subSubtittle, subDescription, textMarked}){
    return(
        <>
        <div className="min-w-[10rem] lg:w-full h-72 md:h-96 flex flex-row items-center lg:flex-col gap-10">
            <div className="lg:w-full h-full lg:h-5 relative">
                <div className="w-2 lg:w-full h-full lg:h-[2px] bg-white absolute top-1/2 -translate-y-1/2"></div>
                <div className="w-5 lg:w-full text-center absolute top-1/3 lg:-top-1 left-[40%] -rotate-90 lg:rotate-0"><Point_step /></div>
            </div>
            
            
            <div className="px-5 text-left lg:text-center">
                <p className="text-2xl font-semibold font-encode text-white underline underline-offset-4 decoration-red mb-5">{tittle}</p>
                <p className="font-dmSans text-base text-white"><span className={textMarked ? 'font-bold bg-gradient-to-t from-red from-40% to-blue-500 to-40%' : ' '}>{subtittle}</span>{description}</p>
                <p className="font-dmSans text-base text-white"><span className={textMarked ? 'font-bold bg-gradient-to-t from-red from-40% to-blue-500 to-40%' : ' '}>{subSubtittle}</span>{subDescription}</p>
            </div>
        </div>
        </>
    )
}