export default function Isotipo_main(){
    return(
        <>
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_573_319)">
        <path d="M49.9029 37.6691C47.8254 40.4521 44.4598 41.698 40.473 41.7822C37.1237 41.8954 34.0979 41.1091 31.7906 39.5557C30.5885 38.7613 29.5723 37.7164 28.8115 36.4926C28.0508 35.2688 27.5635 33.8951 27.3831 32.4655C27.3831 32.4396 27.3831 32.4137 27.3831 32.3911L27.3637 32.2131C27.3637 32.1354 27.3443 32.0545 27.3378 31.9736L27.2698 31.3588C27.2698 26.2523 23.7814 23.9158 19.1797 23.9158C14.578 23.9158 10.9956 26.2393 10.9956 31.3588V36.077C10.8824 39.5428 8.27733 41.012 7.04762 41.1414V20.3044H10.9956V24.0517C12.8661 21.3108 15.8983 20.0261 19.1797 19.9419C23.1633 19.7834 26.9462 21.4046 29.2115 24.5307C31.5123 21.3755 35.6836 19.7251 40.5054 19.8901C44.4922 19.971 47.9483 21.2169 49.9353 23.9191V20.3044H53.9189L53.6276 50.3836C58.7434 44.4333 61.3689 36.7401 60.9582 28.9037C60.5475 21.0673 57.1322 13.6907 51.4225 8.30768C45.7128 2.92466 38.148 -0.0505575 30.301 0.000650208C22.454 0.0518579 14.9286 3.12555 9.28966 8.58263C3.65068 14.0397 0.331973 21.4602 0.0235841 29.3013C-0.284805 37.1424 2.44085 44.8007 7.63382 50.6838C12.8268 56.5669 20.0875 60.2221 27.9062 60.8894C35.7249 61.5568 43.5 59.1849 49.6149 54.2669L49.9029 37.6691Z" fill="white"/>
        <path d="M40.4734 23.8867C34.7391 23.8867 31.2441 26.1196 31.2441 30.7731C31.2441 35.4265 34.7391 37.8018 40.4734 37.8018C46.787 37.8018 49.7804 34.8505 49.7383 30.8216C49.7124 26.2879 46.5799 23.8867 40.4734 23.8867Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_573_319">
        <rect width="61" height="61" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </>
    )
}